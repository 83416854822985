export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const TOGGLE_PLAYER = 'TOGGLE_PLAYER'
export const DATA_LOADING = 'DATA_LOADING'
export const DATA_FAILED = 'DATA_FAILED'
export const TRANSLATE_DATA = 'TRANSLATE_DATA'
export const ADD_RAW_DATA = 'ADD_RAW_DATA'
export const PLAYER_SET_TRACK = 'PLAYER_SET_TRACK'
export const PLAYER_PLAY_PAUSE = 'PLAYER_PLAY_PAUSE'
export const VIDEO_PLAY_PAUSE = 'VIDEO_PLAY_PAUSE'
export const VIDEO_SET_TRACK = 'VIDEO_SET_TRACK'
export const RESP_TOGGLE_MENU = 'RESP_TOGGLE_MENU'
